import { ReloadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Input, message, Modal, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { GENERATE_WORDPRESS_API_KEY } from '../../auth/graphql/Mutations';
import USER_PROFILE from '../../auth/graphql/Queries';

const WordpressApiKey = () => {
  const { getToken } = useContext(AppContext);
  const [currentApiKey, setCurrentApiKey] = useState(null);
  const userToken = getToken();
  const [
    regeneratedWordPressAPIConfirmation,
    setRegeneratedWordPressAPIConfirmation,
  ] = useState(false);

  const [getCurrentUserProfile, { refetch }] = useLazyQuery(USER_PROFILE, {
    onCompleted(res) {
      setCurrentApiKey(res?.userProfile?.apiKey);
    },
  });

  useEffect(() => {
    if (userToken) {
      getCurrentUserProfile();
    }
  }, []);

  const [generateApiKeyQuery, { loading: generateApiKeyLoading }] = useMutation(
    GENERATE_WORDPRESS_API_KEY,
    {
      onCompleted(res) {
        setCurrentApiKey(res?.generateApiKey?.apiKey);
        refetch();
      },
      onError() {},
    },
  );
  const generateApiKey = () => {
    setRegeneratedWordPressAPIConfirmation(false);
    generateApiKeyQuery();
  };
  const copyApiKey = () => {
    try {
      // eslint-disable-next-line no-undef
      navigator?.clipboard?.writeText(currentApiKey);
      message.success('Api key copied successfully!');
    } catch (err) {
      message.error('Failed to copy!');
    }
  };

  return (
    <>
      <Card size="small" className="full-height mb-16" hoverable>
        <div className="d-flex align-center justify-between">
          <h3>Wordpress Api Key</h3>
          <Row gutter={[2, 2]} className="d-flex align-center">
            {currentApiKey && (
              <Col span={12}>
                <Tooltip title="Re-generate key">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<ReloadOutlined spin={generateApiKeyLoading} />}
                    onClick={() => setRegeneratedWordPressAPIConfirmation(true)}
                    disabled={generateApiKeyLoading}
                  />
                </Tooltip>
              </Col>
            )}
            <Col span={currentApiKey ? 12 : 24}>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                className="all-btn full-width"
                onClick={currentApiKey ? copyApiKey : generateApiKey}
                loading={!currentApiKey && generateApiKeyLoading}
              >
                {currentApiKey ? 'Copy' : 'Generate Key'}
              </Button>
            </Col>
          </Row>
          {regeneratedWordPressAPIConfirmation && (
            <Modal
              open={regeneratedWordPressAPIConfirmation}
              onOk={generateApiKey}
              onCancel={() => setRegeneratedWordPressAPIConfirmation(false)}
              className="router-prompt"
              title="Confirm API Key Regeneration"
            >
              <p>
                Regenerating the API key will log you out of WordPress and
                revoke access to your data. Are you sure you want to proceed
                with this action?
              </p>
            </Modal>
          )}
        </div>
        {currentApiKey && <Input value={currentApiKey} disabled />}
      </Card>
    </>
  );
};

export default WordpressApiKey;
