import { Typography } from 'antd';
import React from 'react';

const isURL = (str) => {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern?.test(str);
};

const parseText = (text) => {
  const parts = text.split(/(\s+)/); // Split text into parts by spaces, preserving the spaces

  return parts.map((part) => {
    if (part.startsWith('@')) {
      return (
        <Typography.Link
          href={`https://www.instagram.com/${part.slice(1)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="font-2xl"
        >
          {part}
        </Typography.Link>
      );
    }

    if (part.startsWith('#')) {
      return (
        <Typography.Link
          href={`https://www.instagram.com/explore/tags/${part.slice(1)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="font-2xl"
        >
          {part}
        </Typography.Link>
      );
    }

    if (isURL(part)) {
      return (
        <Typography.Link
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="font-2xl"
        >
          {part}
        </Typography.Link>
      );
    }

    return part; // Plain text or space
  });
};

const DisplayTextWithLinks = ({ text }) => {
  if (!text) return null;

  return <Typography className="font-2xl">{parseText(text)}</Typography>;
};

export default DisplayTextWithLinks;
