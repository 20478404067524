import { FacebookFilled, GoogleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from 'antd';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  ROUTES,
} from '../../common/constants';
import { formValidatorRules, GaEvent } from '../../common/utils';
import { signInWithFacebookPopup, signInWithGooglePopup } from './firebase';
import { LOGIN, SOCIAL_LOGIN } from './graphql/Mutations';

const { required, emailWithPlus } = formValidatorRules;

const Login = () => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const { initializeAuth, setOnboardModal } = useContext(AppContext);

  const [UserLogin, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted(res) {
      const { user } = res?.userLogin || {};
      if (user?.id) {
        GaEvent(GA_EVENT_NAMES.LOGIN, {
          [GA_EVENT_PARAMETERS.LOGIN_TYPE]: 'email and password login',
          [GA_EVENT_PARAMETERS.USER_ID]: user?.id,
        });
      }
    },
    onError() {},
  });
  const [SocialLogin] = useMutation(SOCIAL_LOGIN, {
    onCompleted(res) {
      const { accessToken, refreshToken, user } = res?.socialLogin;
      initializeAuth(accessToken, [], refreshToken);

      /* check for new user for GE event trigger */
      if (user?.isNewUser) {
        GaEvent(GA_EVENT_NAMES.SIGN_UP, {
          [GA_EVENT_PARAMETERS.LOGIN_TYPE]: 'social signup',
          [GA_EVENT_PARAMETERS.USER_ID]: user?.id,
        });
        setOnboardModal(true);
      } else {
        GaEvent(GA_EVENT_NAMES.LOGIN, {
          [GA_EVENT_PARAMETERS.LOGIN_TYPE]: 'social login',
          [GA_EVENT_PARAMETERS.USER_ID]: user?.id,
        });
      }

      history?.replace('/');
    },
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    history?.replace('/');
  }

  function successCallbackSocialAuth(accessToken) {
    SocialLogin({
      variables: {
        token: accessToken,
      },
    });
  }

  const handleGoogleSignIn = async () => {
    const { user } = await signInWithGooglePopup();
    if (user?.accessToken) {
      const accessToken = user?.accessToken;
      if (successCallbackSocialAuth) {
        successCallbackSocialAuth(accessToken);
      }
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const { user } = await signInWithFacebookPopup();
      if (user?.accessToken) {
        const accessToken = user?.accessToken;

        if (successCallbackSocialAuth) {
          successCallbackSocialAuth(accessToken);
        }
      }
    } catch (error) {
      if (error?.code === 'auth/account-exists-with-different-credential') {
        message.error('Account exists with different credentials');
      } else {
        message.error('An unknown error occurred. Please try again later.');
      }
    }
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await UserLogin({
        variables: { input: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.userLogin?.accessToken;
        const userData = response?.data?.userLogin?.user;
        const tokenRefresh = response?.data?.userLogin?.refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };
  const { Title } = Typography;

  return (
    <div className="auth-bg d-flex">
      <div className="login-wrap align-center justify-start d-flex">
        <Spin spinning={loginLoading} wrapperClassName="full-width">
          <Title>
            <center>
              <img src="/logo.png" alt="svg logo" className="auth-logo" />
            </center>
          </Title>
          <Card className="full-width auth-card">
            <Title level={3}>
              <center className="auth-card">Login</center>
            </Title>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              {/* add new validator function to accept login of user with + email ID's */}
              <Form.Item
                name="email"
                rules={[
                  { required, message: 'Please enter email!' },
                  emailWithPlus,
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item className="mb-8">
                <Link to={ROUTES?.FORGET_PASSWORD} className="auth-card">
                  Forgot password ?
                </Link>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
              <Divider className="divider">or connect with</Divider>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      className="full-width btn-social-media"
                      icon={<FacebookFilled />}
                      onClick={handleFacebookSignIn}
                    >
                      Sign in with Facebook
                    </Button>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    <Button
                      className="full-width btn-social-media"
                      icon={<GoogleOutlined />}
                      onClick={handleGoogleSignIn}
                    >
                      Sign in with Google
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <div>
            <center>
              <p className="font-style">
                Don't have an account yet?
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.SIGNUP);
                  }}
                >
                  <u>Signup</u>
                </Button>
              </p>
            </center>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Login;
