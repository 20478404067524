import { Layout, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../AppContext';
import { BREAKPOINTS, PLANS, ROUTES } from '../common/constants';
import RouterPrompt from '../components/RouterPrompt';
import '../i18n';
import AppHeader from './components/header/AppHeader';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;
const App = () => {
  const { state, dispatch } = useContext(AppContext);
  const [blackFridayModal, setBlackFridayModal] = useState(false);
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  useEffect(() => {
    if (
      state?.currentUser?.subscriptionPlan?.key === PLANS.FREE_PLAN.key &&
      !state?.blackFridayOfferModal &&
      process.env.REACT_APP_BLACK_FRIDAY_OFFER
    ) {
      setBlackFridayModal(true);
    }
  }, [state]);

  const handleCancel = () => {
    setBlackFridayModal(false);
    dispatch({ type: 'BLACK_FRIDAY_OFFER_MODAL', data: true });
  };

  return (
    <Layout className="main-wrapper">
      <AppHeader>
        <div
          id="header-title"
          className="header-height text-bold font-large full-width d-flex align-center justify-between"
        />
        <div id="publish" className="text-bold font-large" />
        <div id="profile" className="text-bold font-large" />
      </AppHeader>
      <Layout className="full-width d-flex full-height">
        <RouterPrompt openPrompt={state?.showPrompt} />
        {!isDesktop && (
          <span
            className={
              isActive ? 'active overlay-responsive' : 'overlay-disable'
            }
            onClick={handleOverlay}
          />
        )}

        <Layout>
          <Content className="instaplug-content-container">
            <ContentRoutes />
          </Content>
        </Layout>
      </Layout>
      {process.env.REACT_APP_BLACK_FRIDAY_OFFER && (
        <Modal
          width={600}
          className="black-friday-modal"
          open={blackFridayModal}
          onCancel={handleCancel}
          centered
          footer={null}
        >
          <a
            href={ROUTES?.MANAGE_PRICING}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              setBlackFridayModal(false);
              dispatch({ type: 'BLACK_FRIDAY_OFFER_MODAL', data: true });
            }}
          >
            <img
              src={process.env.REACT_APP_BLACK_FRIDAY_OFFER}
              alt="background"
            />
          </a>
        </Modal>
      )}
    </Layout>
  );
};

export default App;
