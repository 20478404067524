/* eslint-disable jsx-a11y/label-has-associated-control */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import { map } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  LIMIT_KEYS,
  PAYMENT_CHECKOUT,
  PLANS,
  PLAN_FEATURE_GROUP_LABEL,
} from '../../../common/constants';
import { GaEvent, billFormatter } from '../../../common/utils';
import PendingButton from '../../../components/PendingButton';
import USER_PROFILE from '../../auth/graphql/Queries';
import UserInfoRequiredModal from './UserInfoRequiredModal';

const renderTableCellHeader = (condition, key) =>
  PLAN_FEATURE_GROUP_LABEL[condition] && (
    <tr key={key}>
      <td className="dynamic-key-td table-cell-group">
        {PLAN_FEATURE_GROUP_LABEL[condition]}
      </td>
    </tr>
  );

const renderPlanTableBtn = ({
  headers = [],
  currentUser,
  isAgencyUpgrading,
  isProUpgrading,
  isBasicUpgrading,
  disableBtnForPendingStatus,
  proceedToPayment,
}) => (
  <>
    {map(headers, (item) => {
      const isPlanActive =
        currentUser?.subscriptionPlan?.key === item?.plan?.key;

      const isUpgradeActive = {
        BASIC_LIFE_TIME_DEAL:
          currentUser?.subscriptionPlan?.key === PLANS.FREE_PLAN.key &&
          ![
            PLANS.PRO_LIFE_TIME_DEAL.key,
            PLANS.AGENCY_LIFE_TIME_DEAL.key,
          ].includes(currentUser?.subscriptionPlan?.key) &&
          !isAgencyUpgrading &&
          !isProUpgrading,

        PRO_LIFE_TIME_DEAL:
          [PLANS.BASIC_LIFE_TIME_DEAL.key, PLANS.FREE_PLAN.key].includes(
            currentUser?.subscriptionPlan?.key,
          ) && !isAgencyUpgrading,

        AGENCY_LIFE_TIME_DEAL: [
          PLANS.BASIC_LIFE_TIME_DEAL.key,
          PLANS.FREE_PLAN.key,
          PLANS.PRO_LIFE_TIME_DEAL.key,
        ].includes(currentUser?.subscriptionPlan?.key),
      }[item?.plan?.key];

      const isUpgrading = {
        BASIC_LIFE_TIME_DEAL: isBasicUpgrading,
        PRO_LIFE_TIME_DEAL: isProUpgrading,
        AGENCY_LIFE_TIME_DEAL: isAgencyUpgrading,
      }[item?.plan?.key];

      const isDisabled =
        isPlanActive || isUpgrading || disableBtnForPendingStatus;

      return (
        <th
          className="text bg-white-smoke"
          aria-label="empty-th-3"
          key={item?.id}
        >
          {isUpgrading && <PendingButton isDisabled={isDisabled} />}
          {(isPlanActive || isUpgradeActive) && !isUpgrading && (
            <Button
              type="primary"
              size="large"
              className="buy-button"
              disabled={isDisabled}
              onClick={() => proceedToPayment(item?.id, item?.plan?.label)}
            >
              {isPlanActive ? 'Active plan' : 'Upgrade'}
            </Button>
          )}
        </th>
      );
    })}
  </>
);

function PricingTable({
  headers = [],
  data = [],
  registerCustomer,
  subscribeToPlan,
}) {
  const {
    state: { currentUser },
    setCurrentUser,
  } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const plans = data.map((item) => ({
    ...item.features,
  }));
  const [planId, setPlanId] = useState('');

  const [getUser] = useLazyQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setCurrentUser(res?.userProfile);
    },
    onError() {},
  });

  const subscribe = (subscriptionPlanId) => {
    subscribeToPlan({
      variables: {
        subscriptionPlanId,
      },
    });
  };

  const customerIsReady = useMemo(
    () => currentUser?.firstName && currentUser?.lastName,
    [currentUser],
  );

  const buyPlan = async (subscriptionPlanId, planName) => {
    try {
      if (!currentUser?.isRegisteredCustomer) {
        const response = await registerCustomer();
        if (response) {
          await getUser();
          subscribe(subscriptionPlanId);
          return;
        }
        return;
      }
      subscribe(subscriptionPlanId);
      GaEvent(GA_EVENT_NAMES.CLICK_ON_BUY_NOW_BUTTON, {
        [GA_EVENT_PARAMETERS.PLAN_NAME]: planName,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const proceedToPayment = (subscriptionPlanId, planName) => {
    setPlanId(subscriptionPlanId);
    if (!customerIsReady) {
      setOpen(true);
      return;
    }
    buyPlan(subscriptionPlanId, planName);
  };
  const { key: userUpgradeKey, status } =
    currentUser?.userUpgradedSubscription || {};

  const checkPlanUpgrading = (planKey) =>
    userUpgradeKey === planKey && status === PAYMENT_CHECKOUT.STATUS.PENDING;

  const isBasicUpgrading = checkPlanUpgrading(PLANS?.BASIC_LIFE_TIME_DEAL?.key);
  const isProUpgrading = checkPlanUpgrading(PLANS?.PRO_LIFE_TIME_DEAL?.key);
  const isAgencyUpgrading = checkPlanUpgrading(
    PLANS?.AGENCY_LIFE_TIME_DEAL?.key,
  );

  const disableBtnForPendingStatus = headers?.some(
    (item) =>
      (item?.plan?.key === PLANS.PRO_LIFE_TIME_DEAL.key && isProUpgrading) ||
      (item?.plan?.key === PLANS.BASIC_LIFE_TIME_DEAL.key && isBasicUpgrading),
  );

  return (
    <>
      {open && (
        <UserInfoRequiredModal
          open={open}
          setOpen={setOpen}
          planId={planId}
          buyPlan={buyPlan}
        />
      )}

      <div
        id="text-block-9"
        className="mk-text-block jupiter-donut- pricing-table-wrapper mt-8"
      >
        <div className="ip-block">
          <div className="ip-table-responsive">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th className="text bg-white-smoke" aria-label="empty-th-4">
                    <p className="m-0 pricing-description text-capitalize">
                      Our pricing plans are designed to be{' '}
                      <span className="highlight-text">Affordable</span>,
                      <span className="highlight-text"> Flexible</span> and
                      <span className="highlight-text"> Tailored</span> to your
                      unique needs
                    </p>
                  </th>
                  {map(headers, (item) => {
                    const formattedBillAmount = billFormatter(
                      item?.billingAmount,
                    );
                    return (
                      <th className="headding" key={item?.id}>
                        {item?.plan?.key === PLANS?.PRO_LIFE_TIME_DEAL?.key && (
                          <label className="colum-lable">Most Popular</label>
                        )}
                        <span>{item?.plan?.label} </span>
                        <span className="bill-amount">
                          <div className="price-container">
                            <span>${formattedBillAmount}</span>
                            {item?.plan?.original && (
                              <span className="original-price">
                                ${item?.plan?.original}
                              </span>
                            )}
                          </div>
                        </span>
                      </th>
                    );
                  })}
                </tr>

                <tr className="">
                  <th className="text bg-white-smoke" aria-label="empty-th-4" />

                  {renderPlanTableBtn({
                    headers,
                    currentUser,
                    isAgencyUpgrading,
                    isProUpgrading,
                    isBasicUpgrading,
                    disableBtnForPendingStatus,
                    proceedToPayment,
                  })}
                </tr>

                <tr className="sign_up_btn_row hidden-mb">
                  <th className="headding col-1-child-1">Features</th>
                  {map(headers, (item, index) => (
                    <th
                      key={item?.id}
                      className="headding"
                      aria-label={`empty-th-${index}`}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object?.keys(plans?.[0] || {})?.map((key) => (
                  <>
                    {renderTableCellHeader(plans?.[0]?.[key]?.name, key)}

                    <tr key={key}>
                      <td className="dynamic-key-td">
                        {plans?.[0]?.[key]?.name || LIMIT_KEYS?.[key]?.label}
                      </td>
                      {plans?.map((plan, index) => {
                        if (
                          typeof plan?.[key]?.value === 'number' &&
                          plan?.[key]?.value === 0
                        ) {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>
                              Unlimited
                            </td>
                          );
                        }
                        if (typeof plan?.[key]?.value === 'boolean') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>
                              {plan?.[key]?.value ? (
                                <CheckOutlined />
                              ) : (
                                <CloseOutlined className="feature-not-available" />
                              )}
                            </td>
                          );
                        }
                        if (plan?.[key]?.value === 'BUG_FIX') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>
                              Only Bug fix
                            </td>
                          );
                        }
                        if (plan?.[key]?.value === 'BASIC_SUPPORT') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>
                              Basic support
                            </td>
                          );
                        }
                        if (plan?.[key]?.value === 'PRO') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>Pro</td>
                          );
                        }
                        if (plan?.[key]?.value === 'PRIORITY') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>
                              Priority Email Support
                            </td>
                          );
                        }
                        if (plan?.[key]?.value === 'DEDICATED_MANAGER') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <td key={`${key}-${plan?.key}-${index}`}>
                              Dedicated Manager
                            </td>
                          );
                        }
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <td key={`${key}-${plan?.key}-${index}`}>
                            {plan?.[key]?.value ? (
                              plan?.[key]?.value
                            ) : (
                              <CloseOutlined />
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  </>
                ))}
                <tr>
                  <td className="bg-white-smoke" aria-label="empty-td-1" />

                  {renderPlanTableBtn({
                    headers,
                    currentUser,
                    isAgencyUpgrading,
                    isProUpgrading,
                    isBasicUpgrading,
                    disableBtnForPendingStatus,
                    proceedToPayment,
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingTable;
