import { FacebookFilled, GoogleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  GA_EVENT_NAMES,
  GA_EVENT_PARAMETERS,
  REGEX,
  ROUTES,
} from '../../common/constants';
import { GaEvent, formValidatorRules } from '../../common/utils';
import { signInWithFacebookPopup, signInWithGooglePopup } from './firebase';
import { SIGNUP, SOCIAL_LOGIN } from './graphql/Mutations';

const {
  required,
  email,
  nameAndNoSpaceValidator,
  emailWithPlus,
} = formValidatorRules;
const emailValidator =
  process.env.REACT_APP_ENV === 'development' ? emailWithPlus : email;

const Signup = () => {
  const history = useHistory();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const { initializeAuth, setOnboardModal } = useContext(AppContext);
  const [payloadEmail, setPayloadEmail] = useState('');
  const [signupMutate, { loading: signupLoading }] = useMutation(SIGNUP, {
    onError() {}, // Always write this method for error handling in all mutation.
    onCompleted: (e) => {
      GaEvent(GA_EVENT_NAMES.SIGN_UP, {
        [GA_EVENT_PARAMETERS.LOGIN_TYPE]: 'email and password signup',
        [GA_EVENT_PARAMETERS.USER_ID]: e?.signup?.user?.id,
      });
      history?.push({
        pathname: `${ROUTES?.VERIFY_SIGNUP}/${e?.signup?.user?.id}`,
        state: { email: payloadEmail },
      });
    },
  });
  const [SocialLogin] = useMutation(SOCIAL_LOGIN, {
    onCompleted(res) {
      const { accessToken, refreshToken, user } = res?.socialLogin;
      initializeAuth(accessToken, [], refreshToken);

      /* check for new user for GE event trigger */
      if (user?.isNewUser) {
        GaEvent(GA_EVENT_NAMES.SIGN_UP, {
          [GA_EVENT_PARAMETERS.LOGIN_TYPE]: 'social signup',
          [GA_EVENT_PARAMETERS.USER_ID]: user?.id,
        });
        setOnboardModal(true);
      } else {
        GaEvent(GA_EVENT_NAMES.LOGIN, {
          [GA_EVENT_PARAMETERS.LOGIN_TYPE]: 'social login',
          [GA_EVENT_PARAMETERS.USER_ID]: user?.id,
        });
      }
    },
    onError() {},
  });
  function successCallbackSocialAuth(accessToken) {
    SocialLogin({
      variables: {
        token: accessToken,
      },
    });
    history?.replace('/');
  }
  const handleGoogleSignIn = async () => {
    const { user } = await signInWithGooglePopup();
    if (user?.accessToken) {
      const accessToken = user?.accessToken;

      if (successCallbackSocialAuth) {
        successCallbackSocialAuth(accessToken);
      }
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const { user } = await signInWithFacebookPopup();
      if (user?.accessToken) {
        const accessToken = user?.accessToken;

        if (successCallbackSocialAuth) {
          successCallbackSocialAuth(accessToken);
        }
      }
    } catch (error) {
      if (error?.code === 'auth/account-exists-with-different-credential') {
        message.error('Account exists with different credentials');
      } else {
        message.error('An unknown error occurred. Please try again later.');
      }
    }
  };

  const trimInput = (e, fieldName) => {
    const value = e.target.value.trim();
    form.setFieldsValue({ [fieldName]: value });
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        password: values?.password?.trim(),
      };
      setPayloadEmail(formValues?.email);
      signupMutate({
        variables: { details: { ...formValues } },
      });
    } catch (error) {
      if (error?.message) {
        message?.error(error?.message);
      } else {
        message?.error('Something went wrong');
      }
    }
  };

  return (
    <div className="auth-bg d-flex">
      <div className="login-wrap align-center justify-start d-flex">
        <Spin spinning={signupLoading} wrapperClassName="full-width">
          <Title>
            <center>
              <img src="/logo.png" alt="svg logo" className="auth-logo" />
            </center>
          </Title>
          <Card className="full-width auth-card">
            <Title level={3}>
              <center className="auth-card">Sign Up</center>
            </Title>

            <Form
              name="Signup"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item
                name="firstName"
                rules={[
                  { required, message: 'Please Enter First Name' },
                  nameAndNoSpaceValidator,
                ]}
              >
                <Input
                  placeholder="Enter First Name"
                  onChange={(e) => trimInput(e, 'firstName')}
                />
              </Form.Item>

              <Form.Item
                name="lastName"
                rules={[
                  { required, message: 'Please Enter Last Name' },
                  nameAndNoSpaceValidator,
                ]}
              >
                <Input
                  placeholder="Enter Last Name"
                  onChange={(e) => trimInput(e, 'lastName')}
                />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  { required, message: 'Please enter email!' },
                  emailValidator,
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required, message: 'Please enter password!' },
                  {
                    pattern: REGEX?.PASSWORD,
                    message:
                      'Password must be 8-16 characters with at least one letter and one number.',
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" className="full-width" htmlType="submit">
                  Sign Up
                </Button>
              </Form.Item>
              <Divider className="divider">or connect with</Divider>
              <Row>
                <Col xs={24} md={24} sm={24} xl={24}>
                  <Form.Item>
                    <Button
                      className="full-width btn-social-media"
                      icon={<FacebookFilled />}
                      onClick={handleFacebookSignIn}
                    >
                      Sign Up with Facebook
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} sm={24} xl={24}>
                  <Form.Item>
                    <Button
                      className="full-width btn-social-media"
                      icon={<GoogleOutlined />}
                      onClick={handleGoogleSignIn}
                    >
                      Sign Up with Google
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div>
              <center>
                <p>
                  Already have an account?
                  <Button
                    type="link"
                    onClick={() => {
                      history?.replace(ROUTES?.LOGIN);
                    }}
                    size="small"
                    className="auth-card"
                  >
                    <u>Login</u>
                  </Button>
                </p>
              </center>
            </div>
          </Card>
          <div className="mt-40">
            <center>
              <Space>By signing up, you agree to the</Space>
              <br />
              <Space>
                <Button type="link" size="small">
                  <a
                    href="https://instaplug.app/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>Terms of Service</u>
                  </a>
                </Button>
                and
                <Button type="link" size="small">
                  <a
                    href="https://instaplug.app/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>Privacy Policy</u>
                  </a>
                </Button>
              </Space>
            </center>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Signup;
