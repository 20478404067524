import { useMutation } from '@apollo/client';
import { Card, Form, Input } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { formValidatorRules } from '../../../common/utils';
import CurrentPlanCard from '../../../components/CurrentPlanCard';
import { EDIT_PROFILE } from '../../auth/graphql/Mutations';
import CardActionBtn from './CardActionBtn';
import WordpressApiKey from './WordpressApiKey';

const { required, nameAndNoSpaceValidator } = formValidatorRules;

const ProfileCard = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isEdited, setIsEdited] = useState(true);

  const [editProfile, { loading: profileLoading }] = useMutation(EDIT_PROFILE, {
    onCompleted() {
      setIsEdited(true);
    },
    onError() {},
  });

  const saveStateForField = (field, value) => {
    setIsEdited(value.trim() === user?.[field]);
  };

  const profileInitialValues = id
    ? {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      }
    : { firstName: '', lastName: '', email: '' };

  const handleFormSave = () => {
    form.submit();
  };

  const onFinish = (values) => {
    editProfile({
      variables: {
        details: {
          firstName: values?.firstName.trim(),
          lastName: values?.lastName.trim(),
        },
      },
    });
  };

  const trimInput = (e, fieldName) => {
    const value = e.target.value.trim();
    form.setFieldsValue({ [fieldName]: value });
    saveStateForField(fieldName, value);
  };

  return (
    <>
      <Card
        className="profile-card profile-grid-long-item"
        title={
          <CardActionBtn
            title="Profile"
            saveBtnProps={{
              saveBtnLoader: profileLoading,
              saveClickHandler: handleFormSave,
              saveDisableHandler: isEdited,
            }}
          />
        }
      >
        <Form
          layout="vertical"
          initialValues={profileInitialValues}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { ...required, message: 'Please Enter First Name' },
              nameAndNoSpaceValidator,
            ]}
          >
            <Input
              disabled={profileLoading}
              placeholder="Enter First Name"
              allowClear
              onChange={(e) => trimInput(e, 'firstName')}
            />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { ...required, message: 'Please Enter Last Name' },
              nameAndNoSpaceValidator,
            ]}
          >
            <Input
              disabled={profileLoading}
              placeholder="Enter Last Name"
              allowClear
              onChange={(e) => trimInput(e, 'lastName')}
            />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input disabled placeholder="Enter Email" />
          </Form.Item>
        </Form>

        <WordpressApiKey />

        <CurrentPlanCard />
      </Card>
    </>
  );
};

export default ProfileCard;
