import { SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Input, message, Row, Switch, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { debounce, isNull } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  defaultDateFormat,
  LIMIT,
  OFFSET,
  PAGINATION_SORT,
} from '../../common/constants';
import ModerationPreview from './components/ModerationPreview';
import { UPDATE_POST_MODERATION_MEDIA } from './graphql/Mutations';
import { POST_MODERATION_LISTING } from './graphql/Queries';

const { Title } = Typography;

function PostModeration({ sourceData, getWidget }) {
  const { sourceId, sourceType } = sourceData || {};
  const { id } = useParams();
  const { sortBy, sortOn } = PAGINATION_SORT;
  const [value, setValue] = useState('');
  const [scrollY, setScrollY] = useState(550); // Default height
  const [pagination, setPagination] = useState({
    limit: LIMIT,
    offset: OFFSET,
    search: '',
  });
  const [sorter, setSorter] = useState({
    sortBy: sortBy?.DESC,
    sortOn: sortOn?.TIMESTAMP,
  });
  const { data: postData, loading: fetchLoading, refetch } = useQuery(
    POST_MODERATION_LISTING,
    {
      fetchPolicy: 'network-only',
      skip: !id || isNull(sourceData),
      variables: {
        widgetId: id,
        sourceId,
        sourceType,
        pagination: {
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
        sort: {
          sortBy: sorter?.sortBy,
          sortOn: sorter?.sortOn,
        },
        search: pagination?.search,
      },
      onCompleted() {},
      onError() {},
    },
  );

  const [updatePostModerationMedia] = useMutation(
    UPDATE_POST_MODERATION_MEDIA,
    {
      onCompleted() {
        getWidget({
          variables: {
            widgetId: id,
          },
        });
        refetch({
          variables: {
            widgetId: id,
            pagination: {
              offset: pagination?.offset,
              limit: pagination?.limit,
            },
            sort: {
              sortBy: sorter?.sortBy,
              sortOn: sorter?.sortOn,
            },
            search: pagination?.search,
          },
        });
      },
      onError() {},
    },
  );

  // Function to update the scroll height based on window size
  const updateScrollHeight = () => {
    // eslint-disable-next-line no-undef
    const newHeight = window.innerHeight - 300; // Adjust based on your layout
    setScrollY(newHeight);
  };

  useEffect(() => {
    updateScrollHeight(); // Initial call to set the height
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateScrollHeight); // Listen to window resize

    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateScrollHeight); // Clean up
  }, []);

  const handleSwitchChange = ({ postId, showPostValue }) => {
    updatePostModerationMedia({
      variables: {
        where: {
          widgetId: id,
          postId,
          sourceId,
          sourceType,
        },
        data: {
          isShowWidget: showPostValue,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Preview',
      dataIndex: 'caption',
      key: 'caption',
      width: '70%',
      render: (_, allData) => <ModerationPreview data={allData} />,
      sorter: !isNull(sourceData),
    },
    {
      title: 'Show in widget',
      dataIndex: 'show in widget',
      key: 'show in widget',
      render: (_, allData) => (
        <div className="d-flex align-center justify-center">
          <Switch
            defaultChecked={allData?.isShowWidget}
            onChange={(checkedValue) =>
              handleSwitchChange({
                showPostValue: checkedValue,
                postId: allData?.id,
              })
            }
          />
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (date) => dayjs(date).format(defaultDateFormat),
      sorter: !isNull(sourceData),
    },
  ];

  const handleTableChange = (tablePagination, _, tableSorter) => {
    if (tableSorter?.order) {
      const { field, order } = tableSorter;
      setSorter({
        sortOn: field,
        sortBy: order === 'descend' ? sortBy?.DESC : sortBy?.ASC,
      });
    } else {
      setSorter({ sortBy: sortBy?.DESC, sortOn: sortOn?.TIMESTAMP });
    }

    setPagination((prev) => ({
      ...prev,
      limit: tablePagination?.pageSize,
      offset: (tablePagination?.current - 1) * tablePagination?.pageSize || 0,
    }));
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchText) => {
        if (searchText.trim() === '' && searchText !== '') {
          message.error('Space is not allowed');
          setPagination((prev) => ({
            ...prev,
            search: '',
          }));
        } else {
          setPagination((prev) => ({
            ...prev,
            search: searchText.trim(), // Ensure trimmed search text
          }));
        }
      }, 500),
    [],
  );

  const handleSearch = (searchText) => {
    setValue(searchText);
    debouncedSearch(searchText);
  };

  return (
    <div className="ml-30 mr-30">
      <Row gutter={[8, 24]} className="d-flex align-center justify-center">
        <Col span={24}>
          <Row>
            <Col span={20} className="moderation-title">
              <Title level={3}>Post Moderation</Title>
            </Col>
            <Col span={4} className="d-flex align-end justify-center">
              <Input
                allowClear
                disabled={isNull(sourceData)}
                placeholder="Search"
                prefix={<SearchOutlined />}
                className="full-width"
                value={value}
                onChange={(e) => {
                  const inputValue = e?.target?.value;
                  setValue(inputValue);
                  handleSearch(inputValue);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={postData?.getAllMediaData?.data}
            loading={fetchLoading}
            scroll={{ y: scrollY }}
            pagination={{
              total: postData?.getAllMediaData?.count,
              position: ['bottomCenter'],
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  );
}

export default PostModeration;
