import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { REGEX, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { RESET_PASSWORD } from './graphql/Mutations';

const { required } = formValidatorRules;
const { Title } = Typography;

const ChangePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const [resetPassword, { loading: resetLoading }] = useMutation(
    RESET_PASSWORD,
  );
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');

  const onFinish = async ({ password }) => {
    try {
      const response = await resetPassword({
        variables: { data: { resetPasswordToken: token, password } },
      });
      if (response) {
        history?.replace(ROUTES?.LOGIN);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg d-flex">
      <div className="login-wrap d-flex align-center justify-center">
        <Spin spinning={resetLoading} wrapperClassName="full-width">
          <Title>
            <center>
              <img src="/logo.png" alt="svg logo" className="auth-logo" />
            </center>
          </Title>
          <Card className="full-width">
            <div className="text-center">
              <h2 className="text-center">Reset Password</h2>
              <p className="text-center">
                Enter a new password for your account
              </p>
            </div>

            <Form
              name="reset-password"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="password"
                rules={[
                  { required, message: 'Please enter password!' },
                  {
                    pattern: REGEX?.PASSWORD,
                    message:
                      'Password must be 8-16 characters with at least one letter and one number.',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>

              <Form.Item className="full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </div>
    </div>
  );
};

export default ChangePassword;
